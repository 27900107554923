<template>
   <div class="subdistributor-manage-audit-list-wrapper wrapper-scroll-view">
       <div class="cover flex-box-column">
           <div class="hd">
                <van-nav-bar 
               left-arrow 
               safe-area-inset-top 
               right-text="搜索" 
               @click-right="onSearch" 
               class="search" 
               @click-left="back">
                <template #title>
                        <van-search v-model="keyword" class="sm border" shape="round" placeholder="请输入关键字搜索申请单" @search="onSearch" ref="subdistributorauditListSearch"/>
                    </template>
                </van-nav-bar>
                <div class="menu van-hairline--bottom z-index-10">
                    <van-dropdown-menu class="z-index-10" :close-on-click-outside="false">
                        <van-dropdown-item :title="dateMenuTitle" ref="subdistributorauditListMenu">
                            <van-cell center :title="item.text" @click="selectMenuDate(item)" v-for="(item,index) in mixin_dateOptions" :title-class="{active:selectedDateIndex == index}" :key="index"/>
                            <div class="date-interval">
                                <div class="date-interval-item" @click="dateMenuCalendarOpen">{{customizeStartDate ? customizeStartDate :'开始时间'}}</div>
                                <div class="g">一</div>
                                <div class="date-interval-item" @click="dateMenuCalendarOpen">{{customizeEndDate ? customizeEndDate :'结束时间'}}</div>
                                <button 
                                class="confirm date-interval-item" 
                                @click="selectMenuDate({text:'自选时间',value:99})"
                                :class="{live:customizeStartDate && customizeEndDate}"
                                >确定</button>
                            </div>
                        </van-dropdown-item>
                        <van-dropdown-item v-model="orderValue" @change="orderChange" :options="orderTypeOptions"/>
                    </van-dropdown-menu>
                </div>
            </div>
            <div class="bd" ref="auditListScrollView">
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh" :disabled="listError && auditList.length == 0">
                    <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    :offset="100"
                    :error.sync="listError"
                    error-text="请求失败，点击重新加载"
                    @load="onLoad"
                    >
                     <template v-for="item in auditList">
                        <div :key="item.id" class="audit-item">
                            <div class="audit-item__hd">
                                <div class="audit-item__hd_top flex-box-row-space-between">
                                    <p class="distributor-name">
                                        <span>{{item.name}}</span>
                                    </p>
                                    <p class="date">{{item.createdAt}}</p>
                                </div>
                                <div  class="audit-item__hd_bottom flex-box-row">
                                    <span>{{item.managerName}}</span>
                                    <span class="color-999 p-l-5 f-12">{{item.managerPhone}}</span>
                                </div>
                            </div>
                            <div class="audit-item__bd">
                                <div class="audit-item__bd_top van-hairline--bottom">
                                    <p>{{item.address}}</p>
                                    <p v-if="item.updatedAt">处理时间： <span>{{item.updatedAt}}</span></p>
                                    <p v-if="item.remark" class="main-color remark flex-box-row"><span>审核意见： </span><span>{{item.remark}}</span></p>
                                </div>
                                <div class="audit-item__bd_bottom flex-box-row-space-between">
                                    <div class="status" :class="{await:!item.status,faild:item.status == 2}">{{item.status ? item.status== 1 ? '审核通过' : '审核不通过' : '未处理'}}</div>
                                    <div class="toolbar flex-box-row">
                                        <a class="btn van-hairline--surround" :href="'tel:'+item.managerPhone">电话沟通</a>
                                        <button class="btn van-hairline--surround primary" @click="linkTo(item)" v-if="!item.status">审核</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                     </template>   
                    </van-list>
                </van-pull-refresh>
           </div>
       </div>
        <!-- 区间弹窗 -->
        <van-calendar 
        v-model="dateMenuCalendarShow" 
        type="range"
        @confirm="selectMenuDateInterval" 
        color="#00a0e4"
        :min-date="dateMenuMinDate" 
        :max-date="dateMenuMaxDate"
        allow-same-day/>
   </div>
</template>

<script>
import backMixins from "@/mixins/back";
import dateMenu from "@/mixins/dateMenu";
import api from "@/api";
import { getFormatDate,findIndex } from "@/utils";

export default {

    mixins:[backMixins,dateMenu],

    data(){

        return{

            orderTypeTitle:'订单状态',

            option2: [

                { text: '不限', value: 0 },

                { text: '本日', value: 1 },

                { text: '本周', value: 2 },

                { text: '本月', value: 4 },

                { text: '近3个月', value: 6 },

                { text: '近6个月', value: 7 },

            ],

            orderTypeOptions:[

                {text:'未处理',value:0},
                
                {text:'审核通过',value:1},

                {text:"审核不通过",value:2},

            ],







            //当前选中的时间index

            selectedDateIndex:0,

            //自选起始时间

            customizeStartDate:'',

            //自选结束时间

            customizeEndDate:'',

            //非自选时间对象

            dateMenuSelected:{startDate:'',endDate:''},

            //订单状态默认选择

            orderValue:0,

            keyword:'',







            //是否到底

            finished:false,

            //是否加载

            loading:false,

            //是否正在下拉加载

            refreshing:false,

            //是否出错

            listError:false,

            //页码

            page:1,

            //一页请求的数据

            size:20,

            //数据列表

            auditList:[],

            scrollTop:0

        }

    },


    computed:{

        dateCommonMenuOptions(){

            return {

                 //自选起始时间 key值 , 默认为 customizeStartDate

                customizeStartDateKey:"customizeStartDate",

                //自选结束时间 key值 , 默认为 customizeEndDate

                customizeEndDateKey:"customizeEndDate",

                //非自定义时间对象key值，默认为 dateMenuSelected

                dateMenuSelectedKey:"dateMenuSelected",

                //选择以后请求的事件名称 默认为 onSearch

                searchFnKey:'onSearch',

                //当前选择中的时间选项的index，默认为 selectedDateIndex

                index:'selectedDateIndex',

                //menu菜单的ref 默认为dateMenu

                menuRef:'subdistributorauditListMenu',

            }

        }


    },


    methods:{

        orderChange(){

            this.$nextTick(()=>{

                this.onSearch();

            })


        },

        //获取分销商审核数据列表

        updateData(type){

            this.listError=false;

            let _options = {

                page:this.page,

                pageSize:this.size,

                keyword:this.keyword,

                orderShow:this.orderValue

            }

            //添加搜索时间参数

            if(this.selectedDateIndex > 0){

                //只有自选的时候才使用该参数

                if(this.selectedDateIndex == 6 && this.customizeStartDate && this.customizeEndDate){

                    _options.searchDate = [this.customizeStartDate,this.customizeEndDate];


                }else{

                    _options.searchDate = [this.dateMenuSelected.startDate,this.dateMenuSelected.endDate];

                }


            }

            api.getSubdistributorCheckData({

                options:_options,

                success:(res)=>{

                    if(res.type == 200){

                        const last = this.$listLastCheck(res.data.list,this.size)

                        if(!last){

                            this.page++;

                        }

                        this.finished=last;

                        //首先判断是否存在勾选状态的楼盘

                        if(type == 'refresh' || type == 'search'){

                            this.$refs.auditListScrollView.scrollTop=0;

                            this.auditList = res.data.list;

                            this.$toast.clear();

                            this.refreshing=false;

                        }else{

                            this.auditList.push(...res.data.list);

                        }

                    }else{

                        this.listError = true;

                        if(type == 'refresh' || type == 'search'){

                            this.$toast.fail(res.msg || '审核订单数据获取失败');

                        }

                    }


                },

                error:(err)=>{

                    console.log(err);

                    this.listError=true;

                    this.$toast.fail('审核列表获取异常');

                },

                complete:(res)=> {

                    if(type == 'refresh'){

                        this.refreshing=false;

                    }

                    if(res?.code && res.code !=200){

                        this.listError = true;
                        
                    }
                    

                    this.loading=false;
                    
                }


            })



        },

        //下拉加载

        onRefresh(){

            this.page=1;

            this.finished=true;

            this.updateData('refresh');

        },

        //搜索分销商数据列表

        onSearch(){

            this.page=1;

            this.$toast.loading({

                message: '正在加载...',
                forbidClick: true,
                duration:0

            });

            this.updateData('search');

        },

        onLoad(){

            this.updateData();

        },

           //个别数据更新

        auditUpdate(){

            if(!this.$store.state.routerStore.subdistributorAuditRefreshId || this.auditList.length == 0) return;

            api.getSubdistributorCheckDetail({

                options:{id:this.$store.state.routerStore.subdistributorAuditRefreshId},

                success:(res)=>{

                    if(res.type == 200){

                        //检查是否存在对应的元素

                        let _index = findIndex(this.auditList,(el)=>{

                            return el.id == res.data.id;

                        });
                        
                        if(_index !=-1){

                            this.$set(this.auditList,_index,res.data);

                            this.$toast({

                                position:'bottom',

                                message:'已更新数据'

                            });

                        }

                    }

                },

                error:(err)=>{

                    console.log(err);

                },

                complete: ()=> {

                    this.$store.commit('setRouterData',{key:"subdistributorAuditRefreshId",value:0});
                    
                }

            })



        },













        getFormatDate({date}){

            return getFormatDate({date,separator:'',format:"minute"})

        },

        linkTo(item){
            
            //每次跳转都设置一次跳转内容

            this.scrollTop=this.$refs.auditListScrollView.scrollTop;

            this.$router.push({path:`/subdistributor/manage/audit/detail/${item.id}`});

        },
        


    },

    activated(){

        this.$nextTick(()=>{

            if(this.scrollTop){

                this.$refs.auditListScrollView.scrollTop=this.scrollTop;

            }

            
            //检查是否存在需要更新的数据
   
            this.auditUpdate();
            

        })

    }

}
</script>

<style scoped lang="less">
.subdistributor-manage-audit-list-wrapper{

    .hd{

        .menu:after{

            z-index:10;

        }

        .menu .date-interval{

               display: flex;

               padding:15px;

               align-items: center;

               .date-interval-item{

                   font-size:13px;

                   line-height:24px;

                   padding: 2px 8px 1px;

                   background:#f6f6f6;

                   color:#999;

                   &.confirm{

                    margin-left:40px;

                    padding: 2px 15px 1px;

                    background: #eee;

                    border-radius:3px;

                    border: 0;

                    &.live{

                        background: #00a0e4;

                        color:#fff;

                    }

                   }

               }

           }



    }

    .bd{

        background: #f6f6f6;

        .van-pull-refresh{

            min-height:120px;

        }

        .audit-item{

            padding: 15px 16px 20px;

            margin-top:10px;

            background: #fff;

            &:first-child{

                margin-top:0;

            }

            .audit-item__hd{

            

                .distributor-name{

                    font-size:15px;

                    padding-bottom:5px;

                    span:nth-child(2){

                        padding-left:5px;

                        font-size:12px;

                        color:#999999;

                    }

                }

                .audit-item__hd_top{

                    align-items: flex-start;

                    .distributor-name{

                        flex:1;

                        line-height:24px;

                        width: 0;

                    }

                    .date{

                        font-size:12px;

                        color:#999999;

                        padding-left:15px;

                        line-height:24px;

                    }

                }

                .audit-item__hd_bottom{

                    padding-bottom:5px;

                    font-size:13px;

                    align-items: flex-end;

                }

            }

            .audit-item__bd{

                .audit-item__bd_top{

                    padding-bottom:10px;

                    p{

                        padding-bottom:5px;

                    }

                    .remark{

                        padding-top:5px;

                        line-height:20px;

                        span:nth-child(2){

                            flex:1;

                            width: 0;

                        }

                    }

                }

                .audit-item__bd_bottom{

                    padding-top:10px;

                    align-items: center;

                    .status{

                        color:#03bb03;

                        font-size:13px;

                        &.await{
                            
                            color:#00a0e4;

                        }

                           &.faild{

                                    color:rgba(252, 54, 54,.6);

                                }

                    }
                    
                    .toolbar .btn{

                          margin-left:10px;

                          padding: 4px 10px 4px;

                            font-size:12px;
                            
                            border: 0;

                            background: transparent;

                            color:#888888;

                            &:after{

                                border-radius:5px;

                                border-color:#cccccc;

                            }

                         &.primary{

                            color:#00a0e4;


                            &:after{

                                border-color:#00a0e4;

                             

                            }

                            &:active{

                                background: rgba(0, 160, 228,.05);

                            }

                         }
                            

                    }

                }


            }

        }


    }
      
}
</style>
