<template>
   <div class="audit-detail-wrapper">
        <div class="cover flex-box-column">
            <div class="hd">
                <van-nav-bar left-arrow title="分销商注册审核"  @click-left="back"></van-nav-bar>
            </div>
            <div class="bd">
                <van-cell-group>
                    <van-cell title="公司名称" :value="auditData.name" />
                    <van-cell title="负责人"   :value="auditData.managerName"/>
                    <van-cell title="负责人号码" :value="auditData.managerPhone" />
                    <van-cell title="公司地址" :value="auditData.address"/>
                    <van-cell title="对接渠道" :value="auditData.channelName" />
                    <van-cell title="营业执照">
                        <div class="order-images-cover"  v-if="auditData.businessLicensePic">
                            <div class="order-images-show">
                                <div class="order-image" @click="fktImagePreviewShowOpen({item:auditData.businessLicensePic})">
                                    <img :src="auditData.businessLicensePic|$imageUrl" alt="">
                                </div>
                            </div>
                        </div>
                    </van-cell>
                </van-cell-group>
                <van-cell-group class="m-t-10">
                    <van-field label="审核状态" input-align="right">
                        <template #input>
                            <van-radio-group v-model="form.status" direction="horizontal">
                                <van-radio name="1">通过</van-radio>
                                <van-radio name="2">不通过</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <van-field
                    v-model="form.remark"
                    rows="4"
                    label-class="hide"
                    :border="false"
                    type="textarea"
                    placeholder="备注请写在此处"
                    />
                </van-cell-group>    
                <div class="one-page-submit-btn">
                    <van-button
                    round 
                    block 
                    type="info" 
                    native-type="submit" 
                    :disabled="btnDisabled"
                    @click="submitHouseData">提交</van-button>
                </div>
            </div>
        </div>
        <!-- 图片预览 -->
        <van-image-preview 
        v-model="fkyImagePreviewShow" 
        :images="fkyImagePreviewList" 
        :start-position="fkyImagePreviewIndex"
        :max-zoom="3"></van-image-preview>
   </div>
</template>

<script>
import backMixin from "@/mixins/back";
import {pxToVw} from "@/utils";
import imagePreviewMixin from "@/mixins/imagePreview";
import api from "@/api"
export default {

    mixins:[backMixin,imagePreviewMixin],

    data(){

        return{

            auditData:{},
            
            disabled:false,

            //当前选择的参数

            form:{

                status:'',

                remark:''

            }


        }

    },

    computed:{

        btnDisabled(){

            return this.form.status == 0 && !this.form.status

        }


    },

    methods:{

        getVw(px){

            return pxToVw(px) + 'vw';

        },

        //请求楼盘数据

        updateData(){

            this.$toast.loading({

                message:'正在加载...',

                duration:0,

                overlay:true

            });

            api.getSubdistributorCheckDetail({

                options:{

                    id:this.$route.params?.id

                },

                success:(res)=>{

                    if(res.type == 200){

                        this.auditData = res.data;

                        this.disabled=false;
                        
                    }else{

                        this.$toast(res.msg || '获取审核详情数据失败，请稍后重试');

                        this.disabled=true;

                    }
                    
                },

                error:(err)=>{

                    console.log(err);

                    this.$toast('获取审核详情数据失败，加载异常，请稍后重试，'+err);

                    this.disabled=true;

                },

                complete: ()=>{

                    this.$toast.clear();
                    
                }

            })

        },

        //提交楼盘数据

        submitHouseData(){

            //禁用状态下不允许提交

            if(this.disabled){

                this.$toast('没有需要提交的数据');

                return;

            }

            if(this.form.status == 2 && !this.form.remark){

                this.$toast('审核不通过时，请填写审核意见');

                return;

            }

            
            this.$dialog.confirm({

                title:'分销商审核',

                message:'确定要对该订单进行审核吗？',

                confirmButtonText:"确定提交",

                confirmButtonColor:"#00a0e4"


            }).then(()=>{

                this.$toast.loading({

                    message:'正在提交',

                    duration:0,

                    forbidClick:true

                });

                api.setSubdistributorCheckData({

                    options:Object.assign(this.form,{id:this.$route.params.id}),


                    success:(res)=>{

                        if(res.type == 200){

                            this.$dialog.alert({

                                title:'提交成功',

                                message:`${this.auditData.name} 审核成功`,

                                confirmButtonColor:"#00a0e4"

                            }).then(()=>{

                                //设置一下刷新状态

                                this.$store.commit('setRouterData',{

                                    key:'subdistributorAuditRefreshId',

                                    value:this.auditData.id

                                })

                                this.$nextTick(()=>{

                                    this.$router.back();

                                })

                            })

                        }else{

                            this.$toast.fail(res.msg || '提交失败，请稍后重试');

                        }

                    },

                    error:(err)=>{

                         this.$toast({

                             message:"提交失败，数据异常，"+err,

                             duration:5000

                         });

                    },

                    complete: ()=>{

                        this.$toast.clear();
                        
                    }

                })
                

            }).catch(()=>{})


        },










        //初始化方法

        init(){

            this.updateData();

        }

    },


    mounted(){

        this.$fky_callback(()=>{

            this.init();

        },200)

    }

}
</script>

<style scoped lang="less">
.audit-detail-wrapper{

    &,.cover{

        height:100%

    }

    .bd{

        flex:1;

        height:0;

        overflow: hidden;

        overflow-y:auto;

        background: #f7f8fa;

        .van-cell__title{

            flex:0 1 auto;

            padding-right:20px;

        }

        .van-cell__value{

            width: 0;

        }

        .form-item{

            margin-top:10px;

            margin-bottom:16px;

        }

        .thumb-cover{

            width: 100px;

            height:80px;

            img{

                width: 100%;

                height:100%;

            }

        }

        .form-item_title{

            padding: 6px 16px 16px;

            font-size:14px;

            color:rgba(69, 90, 100, 0.6);

        }

    }

}
</style>