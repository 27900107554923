import { getDateFky,dateChange } from "@/utils";
export default{

    data(){

        return {

            //时间选择列表

            mixin_dateOptions: [

                { text: '不限', value: 0 },

                { text: '本日', value: 1 },

                { text: '本周', value: 2 },

                { text: '本月', value: 4 },

                { text: '近3个月', value: 6 },

                { text: '近6个月', value: 7 },

            ],

            //下拉菜单标题

            dateMenuTitle:"时间",

            //日历状态

            dateMenuCalendarShow:false,

            dateMenuMinDate: new Date(2020, 0, 1),

            dateMenuMaxDate: new Date(),


        }

    },

    computed:{

        //定制一个options, 这个options使用来配置调取对应参数的,（此处仅为示例）

        dateCommonMenuOptions(){

            return {

                //自选起始时间 key值 , 默认为 customizeStartDate

                customizeStartDateKey:"customizeStartDate",

                //自选结束时间 key值 , 默认为 customizeEndDate

                customizeEndDateKey:"customizeEndDate",

                //非自定义时间对象key值，默认为 dateMenuSelected

                dateMenuSelectedKey:"dateMenuSelected",

                //menu菜单的ref 默认为dateMenu

                menuRef:'dateMenu',

                //选择以后请求的事件名称 默认为 onSearch

                searchFnKey:'onSearch',

                //当前选择中的时间选项的index，默认为 selectedDateIndex

                index:'selectedDateIndex'

            }

        }


    },


    methods: {
        
        //选择时间

        selectMenuDate(date){

        //自选

        if(date.value == 99 && (!this[this.dateCommonMenuOptions.customizeStartDateKey] || !this[this.dateCommonMenuOptions.customizeEndDateKey])) return;

        if(date.value == 0){

            this.dateMenuTitle='时间'

        }else{

            this.dateMenuTitle=date.text;

        }

        
        this[this.dateCommonMenuOptions.index] = date.value;

        //获取时间区间

        if(date.value !=99 && date.value!=0){

            //拿到区间，转化为字符串

            let inv = getDateFky(date.value);
            
            this[this.dateCommonMenuOptions?.dateMenuSelectedKey].startDate= dateChange(inv.start,'date');

            this[this.dateCommonMenuOptions?.dateMenuSelectedKey].endDate = dateChange(inv.end,'date');

            this[this.dateCommonMenuOptions.customizeStartDateKey] = this[this.dateCommonMenuOptions.customizeEndDateKey] = '';


        }else{

            this[this.dateCommonMenuOptions?.dateMenuSelectedKey].startDate = this[this.dateCommonMenuOptions.customizeStartDateKey];

            this[this.dateCommonMenuOptions?.dateMenuSelectedKey].endDate = this[this.dateCommonMenuOptions.customizeEndDateKey];

        }

        this.$refs[this.dateCommonMenuOptions.menuRef].toggle(false);
            
        this.$nextTick(()=>{

            this[this.dateCommonMenuOptions.searchFnKey]();

        })

        
        },  

        //打开日历

        dateMenuCalendarOpen(){

            this.dateMenuCalendarShow=true;

        },

        //选择日历区间

        selectMenuDateInterval(val){

            this[this.dateCommonMenuOptions.customizeStartDateKey] = dateChange(val[0],'date');

            this[this.dateCommonMenuOptions.customizeEndDateKey] = dateChange(val[1],'date');

            this.$nextTick(()=>{

                this.dateMenuCalendarShow = false;

            })

        },


    },


}